<template>
    <!-- eslint-disable -->
    <div class="trainStandard">
        <van-nav-bar
            title="培训标准列表"
        >
            <template #left>
               <span @click="onClickLeft">
                    <svg-icon icon-class="back-icon" class-name="icon" ></svg-icon>
                </span>
                <span @click="backHome">
			        <svg-icon icon-class="icon-home" class-name="icon icon-home" ></svg-icon>
                </span>
            </template>
        </van-nav-bar>
        <div class="list">
            <div class="item" v-for="(item,index) in workList.slice(0,10)" @click="docPreview(item)" :key="item.work_url">
                <p class="num">{{index<9?0:''}}{{index+1}}</p>
                <p class="name">{{item.work_url_name}}</p>
            </div>
        </div>
        <van-popup v-model:show="popupShow2" @close="close2" class="vocation-box" position="bottom" :style="{ height: '76%' }">
            <p class="close-icon" @click="close2">
              <svg-icon icon-class="close"></svg-icon>
            </p>
            <div class="Vidpreview-container"  >
                <h2 class="name">{{work_name}}</h2>
                <div class="Vidpreview-box">
                    <div id="Vidpreview" style="width:100%;height:500px;"></div>
                </div>
            </div>
            <div class="button" @click="close2">
                <p>已了解</p>
            </div>
        </van-popup>

    </div>
</template>
<script>
export default {
    name:'trainStandard',
    data(){
        return{
            popupShow2:false,
            work_name:'',
            workList:[],

        }
    },
    computed: {
        token(){
            return this.$store.state.token
        }
	},
    created(){
        this.getData()
        
    },
    mounted(){
        
    },
    methods:{
        backHome(){
            this.$router.push('/home')
        },
        close2(){
            this.work_name = "";
            let Vidpreview=document.querySelector('.web-office-default-container')
            if(Vidpreview){
                document.body.removeChild(Vidpreview)
            }
            this.popupShow2 = false
        },
        onClickLeft(){
            this.$router.go(-1);
        },
        /** 查看文档 */
        docPreview(item){
            if(!item.work_url){
                this.$toast('标准建设中');
                return false;
            }
            this.work_name = item.work_url_name;
            this.popupShow2 = true;
            const params = {
                resId: item.work_url
            }
            this.$api.projectIntro.getDocumentAccessToken({params}).then(res => {
                if (res.success) {
                    // 下边是安全才有的
                    this.$nextTick(() => {
                        // eslint-disable-next-line no-undef
                        let demo = aliyun.config({
                            mount: document.querySelector('#Vidpreview'),
                            url: res.data.previewURL, //设置文档查看URL地址。
                        })
                        // //设置AccessToken。
                        demo.setToken({ token: res.data.accessToken })
                    })
                } 
            })
           

        
        },
  
        /** 列表 */
        getData(){
            this.$api.rensheHome.getWorkUrl().then(res => {
                this.workList = res.data || [];
            })
           
        },
    }
}
</script>
<style lang="stylus" src="../asset/css/index.styl" scoped>

</style>
